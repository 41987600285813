import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import StoreLocator from "components/StoreLocator"
import theme from "theme"

const StoreLocatorPage = ({
  data: {
    dispensaryData: { dispensaries },
    productTypeData: { productTypes },
    settings,
  },
}) => {
  function HoodieComponent() {
    useEffect(() => {
      // Create script element and set its source
      const script = document.createElement("script")
      script.src = "https://askhoodie.com/assets/askhoodie.host.js"
      script.async = true

      // Add an event listener to call your function after the script loads
      script.addEventListener("load", function () {
        if (window.hoodieEmbedWtbV2) {
          document.cookie = window.hoodieEmbedWtbV2(
            "550702ae-41c8-453e-bca0-bdfd58f4fff7",
            "askhoodieDiv",
            document.cookie
          )
        }
      })

      // Append the script element to document body
      document.body.appendChild(script)

      // Perform cleanup
      return () => {
        document.body.removeChild(script)
      }
    }, [])

    return <div id="askhoodieDiv"></div>
  }
  return (
    <Layout title="Find a Store" css={{ background: theme.gray1 }}>
      <HoodieComponent />
    </Layout>
  )
}

export default StoreLocatorPage

StoreLocatorPage.propTypes = {
  data: PropTypes.shape({
    dispensaryData: PropTypes.shape({
      dispensaries: StoreLocator.propTypes.locations,
    }).isRequired,
    productTypeData: PropTypes.shape({
      productTypes: StoreLocator.propTypes.productTypes,
    }).isRequired,
    settings: PropTypes.shape({
      ...(StoreLocator.propTypes.settings || {}),
      metadata: PropTypes.shape(Layout.propTypes.metadata),
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  {
    dispensaryData: allSanityDispensary {
      dispensaries: nodes {
        _id
        name
        address {
          street
          city
          state
          postalCode
        }
        phoneNumber
        website
        productTypes {
          _id
          name
          icon {
            ...Image
          }
        }
        hours {
          daysText
          hoursText
        }
        location {
          lat
          lng
        }
      }
    }

    productTypeData: allSanityProductType(sort: { fields: position }) {
      productTypes: nodes {
        _id
        name
        icon {
          ...Image
        }
      }
    }

    settings: sanityStoreLocatorSettings {
      initialCenter {
        lat
        lng
      }
      initialZoom
      initialMarket
      metadata {
        ...Metadata
      }
    }
  }
`
