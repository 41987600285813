import React from "react"
import PropTypes from "prop-types"
import Button from "components/Button"
import theme from "theme"

const MarketSelector = ({ options, state, dispatch, className }) => (
  <div
    css={{
      display: "flex",
      justifyContent: "center",
      boxSizing: "content-box",
      textAlign: "center",
      [theme.max(425)]: {
        width: "100%",
      },
    }}
    className={className}
  >
    {Object.entries(options).map(([id, name]) => (
      <Button
        key={id}
        onClick={() => {
          dispatch({ action: "setMarket", value: id })
        }}
        css={{
          ...theme.h5,
          display: "block",
          flex: "1 0 12em",
          background: state.market === id ? theme.stampRed : theme.greyBrown,
          whiteSpace: "nowrap",
          [theme.max(425)]: {
            flex: "1 1 50%",
          },
          [theme.smol]: {
            padding: "12px 20px",
          },
        }}
      >
        {name}
      </Button>
    ))}
  </div>
)

export default MarketSelector

MarketSelector.propTypes = {
  options: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
}
