import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

const ExpandIcon = ({ open, className }) => (
  <svg className={className} viewBox="0 0 20 20">
    <motion.rect
      animate={{ rotate: open ? 90 + 180 : 0 }}
      transition={{
        type: "spring",
        damping: 15,
        stiffness: 100,
      }}
      x="9"
      width="2"
      height="20"
      fill="currentColor"
    />
    <motion.rect
      animate={{ rotate: open ? 90 : 0, opacity: open ? 0 : 1 }}
      transition={{
        type: "spring",
        damping: 15,
        stiffness: 100,
      }}
      y="9"
      width="20"
      height="2"
      fill="currentColor"
    />
  </svg>
)

export default ExpandIcon

ExpandIcon.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
}
